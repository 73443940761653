import { Routes } from "@angular/router"
import { FrontDoorComponent } from "./features/front-door/front-door.component"
import { PageNotFoundComponent } from "./features/page-not-found/page-not-found.component"
import { UnavailableComponent } from "./features/unavailable/unavailable.component"
import { SessionExpiredComponent } from "./features/session-expired/session-expired.component"
import { SignInOptionsComponent } from "./features/sign-in-options/sign-in-options.component"
import { CreateRequestGuard, PortalHistoryGuard, MultipleSsoGuard, DeleteRequestGuard } from "./guards"
import { RouteName } from "./route-name"
import { RequestReceivedComponent } from "./features/create-request/request-received/request-received.component"
import { DeleteSuccessGuard } from "./features/delete-request/guards/delete-success.guard"
import { ConfirmDeleteRequestComponent } from "./features/delete-request/confirm-delete-request/confirm-delete-request.component"
import { ConfirmDeleteGuard } from "./features/delete-request/guards/confirm-delete.guard"
import { SnagComponent } from "./features/snag/snag.component"
import { GovIdVerificationComponent } from "./features/sign-in-options/gov-id-verification/gov-id-verification.component"
import { MultipleSsoComponent } from "./features/multiple-sso/multiple-sso.component"
import { DataCategoriesComponent } from "./features/data-categories/data-categories.component"
import { VerificationErrorComponent } from "./features/verification-error/verification-error.component"
import { TransparencyReportComponent } from "./features/transparency-report/transparency-report/transparency-report.component"
import { TransparencyReportSpanishComponent } from "./features/transparency-report-spanish/transparency-report-spanish.component"
import { PortalHistoryComponent } from "./features/portal-history/portal-history.component"
import { IntakeFormPageComponent } from "./features/intake-form-page/intake-form-page.component"
import { CorrectionLandingComponent } from "./features/correction-landing/correction-landing.component"
import { CorrectionLandingGuard, IntakeFormGaurd } from "./guards/eligibility.guard"
import { IntakeFormConfirmationPageComponent } from "./features/intake-form-page/intake-form-confirmation-page/intake-form-confirmation-page.component"
import { IntakeConfirmGaurd } from "./features/intake-form-page/gaurds/confirm-intake.guard"
import { IntakeSuccessGuard } from "./features/intake-form-page/gaurds/success-intake.guard"
import { ErrorUriWorkaroundGuard } from "./features/create-request/guards/error-uri-workaround.guard"

export const routes: Routes = [
  { path: "", component: FrontDoorComponent },
  {
    path: RouteName.CreateRequest,
    loadChildren: () =>
      import("./features/create-request/create-request.module").then((mod) => mod.CreateRequestModule),
    canActivate: [CreateRequestGuard],
  },
  {
    path: RouteName.CorrectionLanding,
    component: CorrectionLandingComponent,
    canActivate: [CorrectionLandingGuard],
  },
  {
    path: RouteName.Manage,
    component: PortalHistoryComponent,
    canActivate: [PortalHistoryGuard],
  },
  {
    path: RouteName.IntakeFormPage,
    component: IntakeFormPageComponent,
    canActivate: [IntakeFormGaurd],
  },
  {
    path: RouteName.IntakeFormConfirmationPage,
    component: IntakeFormConfirmationPageComponent,
    canActivate: [IntakeConfirmGaurd],
  },
  {
    path: RouteName.IntakeFormSuccessPage,
    component: RequestReceivedComponent,
    canActivate: [IntakeSuccessGuard],
  },
  {
    path: RouteName.SignIn,
    component: SignInOptionsComponent,
  },
  {
    path: RouteName.TransparencyReport,
    component: TransparencyReportComponent,
  },
  {
    path: RouteName.TransparencyReportSpanish,
    component: TransparencyReportSpanishComponent,
  },
  {
    path: RouteName.GovIdVerification,
    component: GovIdVerificationComponent,
  },
  { path: RouteName.SessionExpired, component: SessionExpiredComponent },
  {
    path: RouteName.MultipleSso,
    component: MultipleSsoComponent,
    canActivate: [MultipleSsoGuard],
  },
  {
    path: RouteName.DeleteRequest,
    loadChildren: () =>
      import("./features/delete-request/delete-request.module").then((module) => module.DeleteRequestModule),
    canActivate: [DeleteRequestGuard],
  },
  {
    path: RouteName.DeleteRequestSuccess,
    component: RequestReceivedComponent,
    canActivate: [DeleteSuccessGuard],
  },
  {
    path: RouteName.DeleteRequestConfirm,
    component: ConfirmDeleteRequestComponent,
    canActivate: [ConfirmDeleteGuard],
  },
  { path: RouteName.Snag, component: SnagComponent },
  { path: RouteName.Unavailable, component: UnavailableComponent },
  { path: RouteName.DataCategories, component: DataCategoriesComponent },
  { path: RouteName.VerificationError, component: VerificationErrorComponent },
  { path: "**", component: PageNotFoundComponent, canActivate: [ErrorUriWorkaroundGuard] },
]
