import {Injectable} from "@angular/core"

@Injectable({providedIn: "root"})
export class ErrorUriWorkaroundGuard {
  constructor() {
  }

  canActivate() {
    const url = new URL(window.location.href);
    if (url.searchParams.has("error_uri")) {
      url.searchParams.delete("error_uri");
      window.location.assign(encodeURI(url.toString()));
      return false;
    }
    return true;
  }
}